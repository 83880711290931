// /* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html, body {
  @apply h-full min-h-screen;
  @apply font-sans;
}

.menu li a {
  @apply text-gray-400 font-semibold;
  svg {
    @apply text-gray-400;
  }
  &.active-link {
    @apply bg-accent text-white font-semibold;
    svg {
      @apply text-white; /* Change icon color when active */
    }
  }
  &:hover {
    @apply text-white;
    svg {
      @apply text-white; /* Change icon color when active */
    }
  }
}

.badge {
  border-radius: 6px;
}

#file-upload {
  display: none;
}

.side-bar {
  background-color: #14191f;
}

.hero {
  background-color: #14191F;
}

.dropdown-content {
  z-index: 99;
  margin-top: 8px;
  li {
    border-bottom-width: 1px;
    @apply p-2 border-gray-200;
    a {
      padding: 8px;
      @apply text-black font-normal;
      &:hover {
        @apply text-black bg-gray-100;
      }
    }
    &:last-child {
      border-width: 0px;
    }
  }
}
