// /* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

html, body {
  @apply h-full min-h-screen;
  @apply font-sans;
}

.menu li a {
  @apply text-gray-400 font-semibold;
  svg {
    @apply text-gray-400;
  }
  &.active-link {
    @apply bg-accent text-white font-semibold;
    svg {
      @apply text-white; /* Change icon color when active */
    }
  }
  &:hover {
    @apply text-white;
    svg {
      @apply text-white; /* Change icon color when active */
    }
  }
}

.side-bar {
  background-color: #14191f;
}

.hero {
  background-color: #14191F;
}